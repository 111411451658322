.ability-display {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 12px;
}

.ability-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 80px;
  background-size: 100% 100%;
  cursor: pointer;
  margin-left: 3px;
  margin-right: 3px;
  aspect-ratio: 212/183;
}

.ability-icon {
  width: 38px;
  aspect-ratio: 189/177;
  margin-bottom: 2px;
  margin-top: 10px;
}

.ability-name {
  color: #ffffff;
  font-size: 8px;
  text-align: center;
}
