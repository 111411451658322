.call-to-action-header {
  position: relative;
  width: 100%;
  height: 116px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -4px;
  margin-bottom: -12px;
}

.cta-platform {
  position: absolute;
  width: 326px;
  height: auto;
}

.cta-header-text {
  position: absolute;
  top: 0px;
  height: 67px;
  width: auto;
  z-index: 1;
}
