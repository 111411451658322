/* PurchaseArmoryItemBottomSheet.css */
.purchase-armory-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 24px;
  padding-bottom: 100px; /* Add padding for button */
  position: relative;
  min-height: 100%;
  box-sizing: border-box;
}

.preview-item-container {
  margin-bottom: 8px;
}

.description-box-container {
  position: relative;
  width: calc(100% - 12px);
  margin-top: -25.7px;
}

.description-box-image {
  width: 100%;
  height: auto;
}

.description-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px;
  color: white;
  font-family: "Microgramma Medium", sans-serif;
  font-size: 16px;
  line-height: 1.4;
  text-align: left;
  display: flex;
  align-items: center;
}

.bottom-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}

.banner-container {
  margin-top: 12px;
  position: relative;
  width: 310px;
  display: flex;
  justify-content: center;
}

.banner-image {
  width: 100%;
  height: auto;
}

.banner-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "Microgramma Bold", sans-serif;
  font-size: 18px;
  text-transform: uppercase;
}

.content-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 8px 0;
}

.level-display {
  color: white;
  font-family: "Microgramma Bold", sans-serif;
  font-size: 38px;
  margin-top: -7px;
}

.level-upgrade-display {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Microgramma Bold", sans-serif;
  font-size: 24px;
}

.level-arrow-container {
  margin-left: 5px;
  margin-right: 8px;
  display: flex;
  width: 64px;
}

.level-arrow {
  display: flex;
  width: 64px;
}

.level-text {
  font-size: 28px;
}

.requirements-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.requirement-item {
  color: white;
  font-family: "Microgramma Bold", sans-serif;
  font-size: 16px;
}

.button-container {
  position: fixed; /* Change from absolute to fixed */
  bottom: calc(20px + env(safe-area-inset-bottom)); /* Adjust as needed */
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  box-sizing: border-box;
  z-index: 10;
}

.action-button {
  width: 310px;
  height: auto;
  transition: transform 0.2s ease;
}

.action-button.clickable {
  cursor: pointer;
}

.action-button.clickable:hover {
  transform: scale(1.05);
}

.action-button.clickable:active {
  transform: scale(0.95);
}
