.daily-spoils-day-item {
  position: relative;
  display: flex;
  cursor: default;
  transition: transform 0.2s ease;
}

.daily-spoils-day-item.full-width {
  width: 100%;
}

.daily-spoils-day-item.full-width .item-center {
  flex: 1;
}

.item-left {
  height: 99px;
  /* object-fit: cover; */
}

.item-center {
  flex: 1;
  height: 99px;
}

.item-right {
  height: 99px;
  /* object-fit: cover; */
}

.item-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  pointer-events: none;
}

.day-text {
  font-family: "Microgramma D Extended", sans-serif;
  color: #ffffff;
  font-size: 15px;
  margin-top: 6.5px;
  margin-bottom: 4px;
}

.loot-icon-daily-spoils {
  height: 31px;
  width: auto;
}

.reward-text {
  font-family: "Microgramma D Extended", sans-serif;
  color: #96fbff;
  font-size: 20px;
  font-weight: bold;
  margin-top: -4.5px;
}
