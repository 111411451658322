.character-display {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
}

.character-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.circle-character-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
  margin-bottom: -36px;
}

.nav-arrow-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.nav-arrow-button.left {
  left: -24px;
}

.nav-arrow-button.right {
  right: -24px;
}

.nav-arrow-button.left img {
  transform: scaleX(-1);
}

.nav-arrow {
  height: 72px;
  aspect-ratio: 254/267;
}

.character-circle {
  max-height: 280px;
  width: auto;
  pointer-events: none;
}

.character {
  position: absolute;
  bottom: 0;
  max-height: 182px;
  width: auto;
  margin-bottom: 41px;
  pointer-events: none;
}

.name-plate {
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  margin-top: 11px;
  margin-bottom: 5px;
  aspect-ratio: 355/50;
}

.character-name {
  color: #ffffff;
  /*font-size handled dynamically in the component*/
  font-family: "Microgramma Bold", sans-serif;
  text-shadow: 0px 5px 3.5px rgba(0, 0, 0, 0.5);
  margin-bottom: 5px;
  width: 100vw;
  text-align: center;
}
