.clan-screen {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.scrollable-content-wrapper {
  flex: 1;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.scrollable-content {
  flex: 1;
  overflow-y: auto;
  width: 100%;
  margin-top: 2px;
}

.section-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.loot-icon {
  width: 13px;
  height: 13px;
  margin-right: 2px;
  margin-top: 2px;
}

.invite-reward-container {
  display: flex;
  flex-direction: row;
  margin-left: 48px;
  margin-bottom: 4px;
  width: calc(100% - 52px);
}

.reward-info {
  font-size: 12px;
  font-family: "Microgramma Medium", sans-serif;
}

.coming-soon-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 68px;
}

.coming-soon {
  font-size: 24px;
}

.bottom-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 72px;
  padding: 0 12px 32px;
  box-sizing: border-box;
  background-color: transparent;
}

.copy-link-button {
  height: 72px;
  width: auto;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.copy-link-button img {
  height: 100%;
  width: auto;
}

.friend-row-detailview-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.loot-icon-friend-row-detail {
  width: 15px;
  height: 15px;
  margin-left: 8px;
  margin-right: 2px;
  margin-top: 2px;
}

.elo-icon-friend-row-detail {
  width: 13px;
  height: 13px;
  margin-left: 21px;
  margin-right: 2px;
  margin-top: 2px;
}

.friend-elo-amount {
  font-size: 12px;
  font-family: "Microgramma Medium", sans-serif;
}

.friend-loot-amount {
  font-size: 12px;
  font-family: "Microgramma Medium", sans-serif;
  margin-left: 1.5px;
}
