.invite-friend-button {
  display: flex;
  height: 72px;
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.invite-left,
.invite-right {
  height: 72px;
  width: auto;
  flex-shrink: 0;
}

.invite-sliver-container {
  flex-grow: 1;
  height: 72px;
  display: flex;
  overflow: hidden;
}

.invite-sliver {
  height: 72px;
  width: 1px;
  flex-grow: 1;
  object-fit: fill;
}
